<template>
  <div class="icon-container">
    <ul class="icon-list">
      <li v-for="item in goodsList" :key="goodsList.value">
        <span>
          <i :class="item.icon"></i>
          <span class="icon-name">{{ item.value }} <span style="color:rgb(239, 184, 56);font-weight:bold">￥{{ item.money }}</span></span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getByTitle } from "@/api/config";
export default {
  name: "exchange",
  data() {
    return {
      goodsList:[]
    };
  },
  created() {
    this.initGoods();
  },
  methods: {
    initGoods() {
      getByTitle("goodsList1")
        .then((res) => {
          this.goodsList = res.data;
        })
        .catch((err) => {
          this.$message.error("获取云端配置失败！");
        });
    },
  },
};
</script>

<style scoped lang="less">
.icon-list {
  overflow: hidden;
  list-style: none;
  padding: 0 !important;
  border: 1px solid #eaeefb;
  border-radius: 4px;
}
.icon-list li {
  float: left;
  width: 16.66%;
  text-align: center;
  height: 120px;
  line-height: 50px;
  color: #666;
  font-size: 13px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-right: -1px;
  margin-bottom: -1px;
}
.icon-list li i {
  display: block;
  font-size: 32px;
  margin-top: 25px;
  color: #606266;
  transition: color 0.15s linear;
}
.icon-list li span {
  line-height: normal;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, SimSun, sans-serif;
  color: #99a9bf;
  transition: color 0.15s linear;
}
.icon-name {
  display: inline-block;
  padding: 0 3px;
  height: 1em;
}
.icon-list li:hover i,
.icon-list li:hover span {
  color: #5cb6ff;
}
</style>
